<template>
  <section class="info-linie part" :class="[ payload.hintergrundfarbe ]">
    <div class="constrain line">
      <div class="row-12">
        <div class="col-12 md:col-6 md:offset-3 lg:col-4 lg:offset-4 flex justify-around items-center">
          <warning />
          <span class="info-text" v-html="payload.info" />
          <warning />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Warning from '@/assets/images/warning.svg?inline';

export default {
  components: { Warning },
  props: { payload: { type: Object, default: () => ({}) } },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.info-linie {
  z-index: 10;

  position: relative;
  font-weight: 900;
  height: calc(#{px(52)} + var(--clip-height) + 1.5em);
  margin-top: calc(var(--clip-height) * -1);
  overflow: hidden;
  pointer-events: none;

  @include responsive('--clip-height', px(100), px(250));
  @include responsive('padding', px(26) 0, px(26) 0);

  .line {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: px(26);
    white-space: nowrap;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: var(--clip-height);
    bottom: 100%;
    width: 100%;
    border-bottom-right-radius: 99999px;
    box-shadow: 0 0 0 9999px var(--color-blue);
  }

  &.orange::before {
    @apply bg-primary;
    box-shadow: 0 0 0 9999px var(--color-primary);
  }
}

svg {
  height: 1.5em;
}
</style>
